import { FormFeedback, FormItem } from "@tesla/design-system-react";
import React from "react";
import "../../css/otp-form.css";

export const OTPForm: React.FC<{
  errorMessage: any,
  formDisabled: boolean,
  inputRef?: any,
  otpValue: string[],
  setOtp: (value: string[]) => void,
  submit: (value: string) => void
}> = ({ errorMessage, formDisabled, inputRef, otpValue, setOtp, submit }) => {
  const BACKSPACE_KEY = 8;
  const ARROW_LEFT_KEY = 37;
  const V_KEY = 86;
  const TAB_KEY = 9;

  const handleKeyDown = (e:any, index:any) => {
    //For users who tries to paste code with ctrl + V, we will ignore this.
    if (e.keyCode === V_KEY) {
      return;
    }
    if (e.keyCode === BACKSPACE_KEY || e.keyCode === ARROW_LEFT_KEY) {

      if (e.target.value && index === 5 && e.keyCode === BACKSPACE_KEY) {
        return;
      }
      if (e.target.previousElementSibling && e.target.previousElementSibling.tagName === "INPUT") {
        e.target.previousElementSibling.focus();
        e.target.previousElementSibling.setSelectionRange(1, 1); //will set cursor at end of input form
        return;
      }
    }
    else if (e.keyCode !== BACKSPACE_KEY && e.target.value) {
      //if user enters a number, it will focus on the next input box if it exists.
      const digitsOnlyValue = e.target.value.replace(/[^0-9]/gi, "");
      if (digitsOnlyValue) {
        otpValue[index] = digitsOnlyValue;
        if (e.target.nextElementSibling) {
          e.target.nextElementSibling.focus();
          e.target.nextElementSibling.setSelectionRange(1, 1);
        }
      }
    }
    //Allows users to Shift + TAB on OTP form
    if (e.shiftKey && e.keyCode === TAB_KEY) {
      if (e.target.previousElementSibling && e.target.previousElementSibling.tagName === "INPUT") {
        e.target.previousElementSibling.focus();
        e.target.previousElementSibling.setSelectionRange(1, 1);
      }
      //Allows users to TAB thru OTP form
    }
    else if (e.keyCode === TAB_KEY) {
      if (e.target.nextElementSibling) {
        e.target.nextElementSibling.focus();
        e.target.nextElementSibling.setSelectionRange(1, 1);
      }
      //Allows user to TAB to button element on last input
      if (index === 5 && e.target.parentNode.parentNode.parentNode.nextSibling) {
        e.target.parentNode.parentNode.parentNode.nextSibling.focus();
      }
    }
    if (!(e.keyCode === BACKSPACE_KEY || e.keyCode === ARROW_LEFT_KEY)) {
      const digitsOnlyValue = e.key.replace(/[^0-9]/gi, "");
      if (!digitsOnlyValue) {
        e.preventDefault();
      }
    }
  };

  const handleOnPaste = (e:any) => {
    e.preventDefault();
    const pasteValue = (e.clipboardData).getData("text");
    const text = pasteValue.replace(/[^0-9]/gi, "")?.substring(0, 6);
    if (!text.length) return;
    let i = 0;
    const otpArray = [...otpValue];
    for (i; i < text.length; i++) {
      otpArray[i] = text[i];
    }
    setOtp(otpArray);
    const index = Math.max(i, text.length - 1);
    inputRef?.current?.[index]?.focus();
    if (text.length === 6) {
      inputRef?.current?.[5]?.focus();
      submit(otpArray?.join(""));
    }
  };

  const handleOnInput = (e: any, index: any) => {
    e.target.value = e.target.value.replace(/[^0-9]/gi, "");
    let text = e.target.value;

    const otpArray = [...otpValue];
    otpArray[index] = text;
    setOtp(otpArray);
    if (index === 5 && text) {
      submit(otpArray?.join(""));
    }

    if (e.target.nextElementSibling && text) {
      e.target.nextElementSibling.focus();
      e.target.nextElementSibling.setSelectionRange(1, 1);
    }

    if (text.length === 6) {
      let i = 0;
      const otpArray = [...otpValue];
      for (i; i < text.length; i++) {
        otpArray[i] = text[i];
      }
      setOtp(otpArray);
      const boxIndex = Math.max(i, text.length - 1);
      inputRef?.current?.[boxIndex]?.focus();
      if (text.length === 6) {
        inputRef?.current?.[5]?.focus();
        submit(otpArray?.join(""));
      }
    }
  };

  return (
    <FormItem className={"otp-form-item"} error={!!errorMessage}>
      <div className={`tds--vertical_padding ${errorMessage ? "tds-form--error" : ""}`}>
        <div className={"otp-form-container"} id="otp-form">
          {otpValue.map((v: any, index: any) => {
            return (
              <input
                autoFocus={index === 0}
                autoComplete={index === 0 ? "one-time-code" : undefined}
                className={"text-code-form"}
                disabled={formDisabled}
                inputMode="tel"
                key={`codeBox${index}`}
                maxLength={index !== 0 ? 1 : undefined}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onInput={(e) => handleOnInput(e, index)}
                onPaste={(e) => handleOnPaste(e)}
                ref={inputRef? (el) => (inputRef.current[index] = el) : null}
                type="tel"
                value={v}
              />
            );
          })}
        </div>
        <FormFeedback className={`${"phone-form-feedback"}`} feedbackText={errorMessage} />
      </div>
    </FormItem>
  );
};

export default OTPForm;
